<template>
  <v-dialog v-model="dialog" max-width="500px">
    <template v-slot:activator="{ on, attrs }">
      <v-icon
          v-on="on"
          v-bind="attrs"
          color="neuter"
      >
        mdi-trash-can-outline
      </v-icon>
    </template>
    <v-card>
      <v-card-title class="text-h6 secondary t-bw-secondary--text">Are you sure you want to delete this wallet?</v-card-title>
      <v-card-text class="pa-6">
        <strong>{{ item.name }}:</strong> {{ item.address | truncateAddress(7) }}
      </v-card-text>
      <v-card-actions class="pa-6">
        <v-spacer></v-spacer>
        <v-btn color="neuter" text @click="closeAction" :disabled="loading">Cancel</v-btn>
        <v-btn color="error" elevation="0" class="t-bw-error--text" @click="deleteAction" :loading="loading">Delete</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "WalletDeleteDialog",

  props: {
    item: {
      type: Object,
      required: true
    },
    index: {
      required: true
    }
  },

  data:() => ({
    dialog: false,
    loading: false
  }),

  computed: {
    wallets: {
      get() {
        return this.$store.getters['web3/GET_WALLETS']
      },
      set(val) {
        this.$store.commit('web3/WALLETS_DATA', val)
      }
    }
  },

  methods: {
    ...mapActions({
      deleteWallet: 'web3/DELETE_WALLET'
    }),
    async deleteAction() {
      this.loading = true
      await this.deleteWallet(this.item).finally(() => {
        this.closeAction()
      })
    },
    closeAction() {
      this.loading = false
      this.dialog = false
    }
  }
}
</script>

<style scoped>
.v-icon {
  font-size: 16px !important;
}
</style>